<template>
  <div class="table-wrapper">
    <el-table :data="dataSource" v-loading="loading" :max-height="maxHeight" :border="border" :stripe="stripe" style="width: 100%" :highlight-current-row="highlightCurrentRow" @current-change="handleSelectItem($event)" @selection-change="handleSelectionChange($event)" ref="multipleTable">
      <el-table-column label="序号" type="index" width="50" v-if="index" align="center"> </el-table-column>
      <el-table-column v-for="item in columns" :prop="item.prop" :label="item.label" :key="item.prop" :width="item.width" :align="item.align" :sortable="item.sortable">
        <template slot="header" v-if="item.describe">
          {{ item.label }}
          <el-tooltip class="item" effect="dark" :content="item.describe" placement="top">
            <span><i class="el-icon-question"></i></span>
          </el-tooltip>
        </template>

        <template slot-scope="scope">
          <!-- 普通文本内容 -->
          <template v-if="item.type === 'text'">
            {{ scope.row[item.prop] === null ? '----' : scope.row[item.prop] }}
          </template>

          <template v-if="item.type === 'number'">
            {{ scope.row[item.prop] || 0 }}
            <!-- <el-tooltip class="item" effect="dark" :content="item.describe"  placement="top" v-show="item.describe" >
                <span>{{ scope.row[item.prop] || 0 }}</span>
              </el-tooltip>
              <span v-show="!item.describe">{{ scope.row[item.prop] || 0 }}</span> -->
          </template>

                <template v-if="item.type === 'abnormal_number'">
                     <span v-if="scope.row[item.prop] > 3000000" style="color:red">{{ scope.row[item.prop] }}</span>
                     <span v-else >{{ scope.row[item.prop] || 0 }}</span>
                  </template>

            <template v-if="item.type === 'result_text'">
              <span :class="scope.row[item.prop] === 1 ? 'success' : 'alarm'">{{ scope.row[item.prop] === 1 ? '误报' : '真实警情' }}</span>
            </template>

            <!-- 报警图标 -->
            <template v-if="item.type === 'icon'">
              <i class="iconfont iconanquan" style="color: #20c77f" v-show="scope.row[item.prop] === '正常'"><span style="font-size: 14px">正常</span></i>
              <i class="iconfont iconbaojingxinxi" style="color: #ff9900; font-size: 20px" v-show="scope.row[item.prop] === '预警'"><span style="font-size: 14px; padding-left: 2px">预警</span></i>
              <i class="iconfont icongaofengxian" style="color: red; font-size: 18px" v-show="scope.row[item.prop] === '报警'"><span style="font-size: 14px; padding-left: 2px">报警</span></i>
            </template>

            <template v-if="item.type === 'icon2'">
              <i class="iconfont iconanquan" style="color: #20c77f" v-show="scope.row[item.prop] === 1"><span style="font-size: 14px">正常</span></i>
              <i class="iconfont iconbaojingxinxi" style="color: #ff9900; font-size: 20px" v-show="scope.row[item.prop] === 2"><span style="font-size: 14px; padding-left: 2px">预警</span></i>
              <i class="iconfont icongaofengxian" style="color: red; font-size: 18px" v-show="scope.row[item.prop] === 3"><span style="font-size: 14px; padding-left: 2px">报警</span></i>
            </template>

            <!-- 按钮 -->
            <template v-if="item.type === 'button'">
              <el-button :type="item.buttonInfo.type" :size="item.buttonInfo.size" :icon="item.buttonInfo.icon" @click="handleClick(scope.row, item.prop)">{{ item.buttonInfo.text || scope.row[item.prop] }}</el-button>
            </template>
            <!-- 开关 -->
            <template v-if="item.type === 'switch'">
              <el-switch v-model="scope.row[item.prop]"></el-switch>
            </template>
          </template>

        </el-table-column>
        <el-table-column type="selection" width="55" v-if="selection" align="center"> </el-table-column>
          <slot></slot>
    </el-table>
    <el-pagination v-if="total >= 10" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  filters: {},
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Boolean,
      default: false,
    },
    selection: {},
    stripe: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    total: {
      type: Number,
    },
    maxHeight: {
      type: Number,
    },
    highlightCurrentRow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(row, type) {
      if (type === 'del') {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$emit('handleClick', row, type)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      } else {
        this.$emit('handleClick', row, type)
      }
    },
    // 表格勾选状态
    handleSelectionChange(e) {

      this.$emit('handleSelectionChange', e)
    },
    handleSelectItem(e) {
      this.$emit('handleSelectItem', e)
    },
    setCurrent() {
      this.$refs.multipleTable.clearSelection()
    },
    clearSelect() {
      this.$nextTick(() => {
        console.log('删除', this.$refs.multipleTable)
        this.$refs.multipleTable.clearSelection()
      })
    },
    // 修改 表格条数
    handleSizeChange(val) {
      console.log('每页' + val + '条')
      this.$emit('handleSizeChange', val)
    },
    // 修改 表格页数
    handleCurrentChange(val) {
      console.log('第' + val + '页')
      this.$emit('handleCurrentChange', val)
    },
  },
  created() {
    // setTimeout(()=>{this.clearSelect()},10000)
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
// @import '@assets/css/font_icon.css';
// @import url('@assets/css/font-icon.css');
@import url('../assets/css/font-icon.css');

.table-wrapper {
  //   height: calc(100% - 120px);
  //   border-radius: 15px;
  //   padding: 15px;
  .el-table {
    margin-bottom: 20px;
  }
}
.not {
  color: #d9d9d9;
}
.table-wrapper ::v-deep .el-table td {
  height: 60px;
  line-height: 60px;
}
.success {
  color: #67c23a;
}
.alarm {
  color: #f56c6c;
}
</style>
