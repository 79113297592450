import service from '../utils/request'

/*  
   查询主机--------------------------------------------------------------------------------------------------------
*/
// 查询所有主机
export function findAllHost(page, pageSize) {
  return service({
    method: 'get',
    url: '/host/findBy',
    params: {
      page,
      pageSize,
    },
  })
}

// 查询单位下的所有主机
export function findHostByUnit(companyId, page, pageSize) {
  return service({
    method: 'get',
    url: '/host/findBy',
    params: {
      companyId,
      page,
      pageSize,
    },
  })
}

// 查询管理员的主机
export function findHostByUserIdHost(userId, page, pageSize) {
  return service({
    method: 'get',
    url: '/host/findBy',
    params: {
      userId,
      page,
      pageSize,
    },
  })
}

// 查询普通用户的主机
export function findHostByUserIdUser(userId) {
  return service({
    method: 'get',
    url: '/host/findByUser',
    params: {
      userId,
    },
  })
}

// 查询主机详情
export function findHostById(id) {
  return service({
    method: 'get',
    url: '/host/findBy',
    params: {
      id,
      page: 0,
      pageSize: 100,
    },
  })
}

/*  
   查询主机 下的 普通用户--------------------------------------------------------------------------------------------------------
*/
export function findUserByHost(hostId) {
  return service({
    method: 'get',
    url: '/host/findByUser',
    params: {
      hostId,
    },
  })
}
/*  
   编辑主机--------------------------------------------------------------------------------------------------------
*/

//  主机管理员重新分配权限
export function updateHostHost(userId, hostId) {
  return service({
    method: 'put',
    url: '/host/distributionHostByUser',
    params: {
      userId,
      hostId,
    },
  })
}
// 普通用户重新分配权限
export function updateUserHost(userId, hostId) {
  return service({
    method: 'put',
    url: '/host/distributionHost',
    params: {
      userId,
      hostId,
    },
  })
}

/* 
主机修改用户权限
*/

export function updateHostUser(hostId, userId) {
  return service({
    method: 'put',
    url: '/host/distributionUser',
    params: {
      hostId,
      userId,
    },
  })
}

export function updateHostHostUser(hostId, userId) {
  return service({
    method: 'put',
    url: '/host/distributionUserByHost',
    params: {
      hostId,
      userId,
    },
  })
}
