<template>
  <div class="userHost">
    <el-row>
      拥有主机:

      <span v-for="item in userHost" :key="item.pkHostId">
        {{ item.hostNumber + ',' }}
      </span>
    </el-row>
    <el-row>
      可选主机:
      <el-select v-model="hostId" multiple placeholder="请选择" v-show="this.role === 2">
        <el-option v-for="item in unitHost" :key="item.value" :label="item.hostNumber" :value="item.pkHostId" :disabled="item.disabled">
          <p class="opt_item">
            {{ item.hostNumber }}
            <span style="float: right; color: green; font-size: 13px" v-show="!item.fkHostUserId">new</span>
          </p>
        </el-option>
      </el-select>
      <el-select v-model="hostId" multiple placeholder="请选择" v-show="this.role === 1">
        <el-option v-for="item in unitHost" :key="item.value" :label="item.hostNumber" :value="item.pkHostId" :disabled="item.disabled">
          <p class="">
            {{ item.hostNumber }}
            <span style="float: right; color: green; font-size: 13px" v-show="item.fkHostUserId">new</span>
          </p>
        </el-option>
      </el-select>
    </el-row>

    <el-row><el-button type="primary" @click="updateUserHostFun">立即修改</el-button> <el-button @click="onCancel">取消</el-button></el-row>
  </div>
</template>

<script>
// import { findUserHost, updateUserHost } from '@/api/User'
import { findHostByUserIdHost, findHostByUserIdUser, findHostByUnit, updateHostHost, updateUserHost } from '@/api/Host.js'
// import qs from 'qs'
export default {
  data() {
    return {
      unitHost: [],
      userHost: [],
      hostId: [],
    }
  },
  props: {
    userId: {
      type: Number,
    },
    unitId: {
      type: Number,
    },
    role: {
      type: Number,
    },
  },
  methods: {
    /*
      根据不同的用户 返回不同的数据
      admin     查询所有主机
      系统管理员 查询单位所有主机
      主机管理员 查询用户拥有的所有主机
      普通用户   查询用户拥有的所有主机
    */
    // async findHostByUser() {
    //   this.loading = true
    //   if (this.role === 2) {
    //     return await findHostByUserIdHost(this.userId, 0, 99999)
    //   }
    //   if (this.role === 1) {
    //     return await findHostByUserIdUser(this.userId, 0, 99999)
    //   }
    // },

    async findAllHostByUser() {
      if (this.role === 2) {
        const { data: res } = await findHostByUserIdHost(this.userId, 0, 99999)
        this.userHost = res.data.list
      }
      if (this.role === 1) {
        const { data: res } = await findHostByUserIdUser(this.userId)
        this.userHost = res.data
        this.userHost.forEach((item) => {
          item.hostNumber = item.host.hostNumber
        })
      }
    },

    async findAllHostByUnit() {
      const { data: res } = await findHostByUnit(this.unitId, 0, 9999)
      this.unitHost = res.data.list

      this.unitHost.forEach((item) => {
        if (this.role === 2) {
          if (item.fkHostUserId) {
            //   已经绑定的管理员ID 于目前操作的管理员ID 不同 为禁选
            item.fkHostUserId !== this.userId ? (item.disabled = true) : false
          }
        }
        // if (this.role === 1) {
        //   console.log(this.userHost)

        // }
        item.companyName = item.company.companyName
      })
    },

    async updateUserHostFun() {
      const hostId = this.hostId.join()
      if (this.role === 2) {
        const { data: res } = await updateHostHost(this.userId, hostId)
        this.$message({
          message: res.message,
          type: 'success',
        })
      }
      if (this.role === 1) {
        const { data: res } = await updateUserHost(this.userId, hostId)
        this.$message({
          message: res.message,
          type: 'success',
        })
      }
      this.$parent.handleClose()
      this.$parent.$parent.findAllUserFun()
    },
    // async findUserHostFun() {
    //   const { data: res } = await findUserHost(this.userId, this.role)
    //   this.userHost = res.data
    //   console.log(this.userHost)
    // },
    // async findHostByUnitFun() {
    //   const { data: res } = await findUnitHost(this.unitId, 0, 99999)
    //   this.unitHost = res.data.list
    //   // disabled
    //   if (this.role === 'host') {
    //     this.unitHost.forEach((item) => {
    //       //   有绑定管理员的主机
    //       if (item.fkHostUserId) {
    //         //   已经绑定的管理员ID 于目前操作的管理员ID 不同 为禁选
    //         item.fkHostUserId !== this.userId ? (item.disabled = true) : false
    //       }
    //     })
    //   }
    //   //   管理员重新选择的 只有未绑定或者 属于自己的主机
    //   console.log(this.unitHost)
    // },
    // async updateUserHostFun() {
    //   console.log(this.hostId)
    //   const hostId = this.hostId.length === 0 ? 0 : this.hostId.join()
    //   const { data: res } = await updateUserHost(this.userId, this.role, hostId)
    //   this.messageFun(res)
    // },
    // messageFun(res) {
    //   if (res.code === 200) {
    //     this.$message({
    //       message: res.message,
    //       type: 'success',
    //     })
    //   } else {
    //     this.$message({
    //       message: res.message,
    //       type: 'error',
    //     })
    //   }
    //   this.form = null
    //   this.$parent.handleClose()
    //   // 因为element Ui 多层组件嵌套  所以$parent 是找到了 elementUi的组件
    //   this.$parent.$parent.findAllUserFun()
    // },
    // submitForm() {
    //   console.log(this.hostId)
    // },
    onCancel() {
      this.$message('取消添加用户')
      this.$parent.handleClose()
    },
  },
  created() {
    this.findAllHostByUser()
    this.findAllHostByUnit()

    // this.findUserHostFun()
    // this.findHostByUnitFun()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.userHost {
  // padding-bottom: 20px;
}
.el-row {
  // margin-top: 20px;
  line-height: 50px;
  height: 50px;
  margin-bottom: 10px;
}
.el-select {
  width: 300px;
}
// .opt_item {
//     position: relative;
//     span{
//         position: abs($number: 0);
//     }
// }
</style>
