<template>
  <div class="userManager">
    <el-row>
      <el-button type="primary" icon="el-icon-plus" @click="dialogVisibleUser = true">创建新用户</el-button>
      <!-- <el-select v-model="fkCompanyId" filterable clearable placeholder="单位查看用户">
        <el-option v-for="item in unitList" :key="item.pkCompanyId" :label="item.companyName" :value="item.pkCompanyId"></el-option>
      </el-select> -->
    </el-row>
    <MyTable :dataSource="dataSource" :columns="columns" :loading="loading" :total="total" index :currentPage="currentPage" :pageSize="pageSize" border stripe @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" @handleClick="handleClick"></MyTable>

    <el-dialog title="用户信息" :visible.sync="dialogVisibleUser" width="600px" :before-close="handleClose">
      <UserDialog :id="userId" v-if="dialogVisibleUser"></UserDialog>
    </el-dialog>
    <div class="host">
      <el-dialog title="用户主机信息" :visible.sync="dialogVisibleHost" width="600px" :before-close="handleClose">
        <UserHostDialog :userId="userId" :unitId="unitId" :role="role" v-if="dialogVisibleHost"></UserHostDialog>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import UserDialog from './UserDialog.vue'
import UserHostDialog from './UserHostDialog.vue'

import { findUserListByUnit, findAllUser, deleteUser } from '@/api/User.js'

const dataSource = []
export default {
  data() {
    return {
      // 单位
      unitList: [],
      fkCompanyId: '',
      // 表格
      // index: true,
      currentPage: 0,
      pageSize: 10,
      total: 0,
      loading: true,
      // 表格数据
      dataSource,
      columns: [],
      // 弹窗部分
      dialogVisibleUser: false,
      dialogVisibleHost: false,
      userId: null,
      unitId: null,
      role: null,
    }
  },
  components: { MyTable, UserDialog, UserHostDialog },

  methods: {
    async findAllUser() {
      this.loading = true
      // 其他人员无法查看单位用户 超级管理员和系统管理员权限
      if (this.$store.state.power < 3) {
        this.dataSource = []
        return
      }
      if (this.$store.state.power === 3) {
        this.columns = [
          {
            prop: 'name',
            label: '用户名',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'userName',
            label: '登录名',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'phone',
            label: '电话号码',
            type: 'text',
            align: 'center',
          },

          {
            prop: 'roleName',
            label: '用户权限',
            type: 'text',
            align: 'center',
          },

          {
            prop: 'view_host',
            label: '查看主机',
            align: 'center',
            type: 'button',
            width: 80,
            buttonInfo: {
              text: '主机',
              type: 'primary',
              size: 'mini',
              // icon: 'el-icon-monitor',
            },
          },
          {
            prop: 'edit',
            label: '修改',
            align: 'center',
            type: 'button',
            width: 80,
            buttonInfo: {
              text: '修改',
              type: 'warning',
              size: 'mini',
              // icon: 'el-icon-edit',
            },
          },
          {
            prop: 'del',
            label: '删除',
            align: 'center',
            type: 'button',
            width: 80,
            buttonInfo: {
              text: '删除',
              type: 'danger',
              size: 'mini',
              // icon: 'el-icon-delete',
            },
          },
        ]
        return await findUserListByUnit(this.$store.state.unitId, this.currentPage, this.pageSize)
      }
      if (this.$store.state.power === 4) {
        this.columns = [
          {
            prop: 'createTime',
            label: '创建日期',
            width: 180,
            align: 'center',
            type: 'text',
            sortable: true,
          },
          {
            prop: 'name',
            label: '用户名',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'userName',
            label: '登录名',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'phone',
            label: '电话号码',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'companyName',
            label: '用户单位--(admin可视)',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'roleName',
            label: '用户权限',
            type: 'text',
            align: 'center',
          },

          {
            prop: 'view_host',
            label: '查看主机',
            align: 'center',
            type: 'button',
            width: 80,
            buttonInfo: {
              text: '主机',
              type: 'primary',
              size: 'mini',
              // icon: 'el-icon-monitor',
            },
          },
          {
            prop: 'edit',
            label: '修改',
            align: 'center',
            type: 'button',
            width: 80,
            buttonInfo: {
              text: '修改',
              type: 'warning',
              size: 'mini',
              // icon: 'el-icon-edit',
            },
          },
          {
            prop: 'del',
            label: '删除',
            align: 'center',
            type: 'button',
            width: 80,
            buttonInfo: {
              text: '删除',
              type: 'danger',
              size: 'mini',
              // icon: 'el-icon-delete',
            },
          },
        ]
        return await findAllUser(this.currentPage, this.pageSize)
      }
    },

    async findAllUserFun() {
      const { data: res } = await this.findAllUser()
      this.loading = false
      console.log(res)
      this.dataSource = res.data.list
      this.dataSource.forEach((item) => {
        item.jurisdiction ? (item.roleName = item.jurisdiction.roleName) : null
        item.company ? (item.companyName = item.company.companyName) : null
      })

      this.total = res.data.total
    },

    async deleteUserFun(id) {
      const { data: res } = await deleteUser(id)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.findAllUserFun()
    },

    handleClick(row, type) {
      console.log(row)
      switch (type) {
        case 'del':
          console.log('del')

          row.fkJurisdictionId >= this.$store.state.power
            ? this.$message({
                message: '抱歉，您无此权限!',
                type: 'error',
              })
            : this.deleteUserFun(row.pkUserId)

          break
        case 'edit':
          this.userId = row.pkUserId
          this.dialogVisibleUser = true
          break
        case 'view_host':
          console.log(row)
          if (row.fkJurisdictionId >= 3) {
            this.$message({
              message: '您好!,' + row.roleName + '请在监测主机管理查看您的主机',
              type: 'success',
            })
          } else {
            this.userId = row.pkUserId
            this.unitId = row.fkCompanyId
            this.role = row.fkJurisdictionId
            this.dialogVisibleHost = true
          }
          break
      }
    },
    handleClose() {
      this.dialogVisibleUser = false
      this.dialogVisibleHost = false
      // 用户取消修改 或者修改成功后 userId 置空
      this.userId = null
    },
    userInfoOpen() {
      this.dialogVisibleUser = true
    },
    handleSizeChange(val) {
      this.pageSize = val
      console.log('this.pageSize', this.pageSize)
      this.findAllUserFun()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      console.log('this.currentPage', this.currentPage)
      this.findAllUserFun()
    },
  },
  created() {
    this.findAllUserFun()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.userManager {
  padding: 10px;
  width: calc(100% - 20px);

  .el-row {
    margin-top: 0px;
    margin-bottom: 10px;
    .left_20 {
      margin-left: 20px;
    }
  }
  .table-wrapper {
    height: calc(100% - 120px);
    border-radius: 15px;
    // padding: 15px;

    .el-table {
      margin-bottom: 20px;
    }
  }
}

.userManager ::v-deep .el-dialog {
  max-height: 560px;
}

.userManager .host ::v-deep .el-dialog {
  height: 300px;
}
</style>
