<template>
  <div class="user">
    <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="所属单位" prop="fkCompanyId" v-show="isShow">
        <el-select v-model="form.fkCompanyId" filterable placeholder="请选择注册单位" :disabled="!!id">
          <el-option v-for="item in unitList" :key="item.pkCompanyId" :label="item.companyName" :value="item.pkCompanyId"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="用户权限" prop="fkJurisdictionId" :disabled="id">
        <el-select v-model.number="form.fkJurisdictionId" placeholder="请选择角色类型" :disabled="!!id">
          <!-- <el-option label="单位管理员" :value="3"></el-option> -->
          <el-option label="主机管理员" :value="2"></el-option>
          <el-option label="普通" :value="1"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="用户名" prop="name">
        <el-input v-model.trim="form.name" :disabled="!!id"></el-input>
      </el-form-item>

      <el-form-item label="登录名" prop="userName">
        <el-input v-model.trim="form.userName" :disabled="!!id"></el-input>
      </el-form-item>

      <el-form-item label="登录密码" prop="password" :rules="this.id ? (rules.password = [{ required: false }]) : (rules.password = [{ required: true, message: '密码必填' }])">
        <el-input v-model.trim="form.password" show-password></el-input>
      </el-form-item>

      <el-form-item label="手机号码" prop="phone" :rules="form.fkJurisdictionId === 2 ? (rules.phone = [{ required: true, message: '主机管理员手机号必填' }]) : (rules.phone = [{ required: false }])">
        <el-input v-model.number="form.phone" maxlength="11"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">{{ id ? '立即修改' : '立即创建' }}</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import md5 from 'js-md5'
import { register, findUserById, updateUser } from '@/api/User.js'
import { findAllCompany } from '@/api/Company.js'
export default {
  data() {
    return {
      form: {
        fkJurisdictionId: null,
        fkCompanyId: null,
        name: null,
        userName: null,
        password: null,
        phone: null,
      },
      rule: '',
      phoneFlag: false,
      rules: {
        fkJurisdictionId: [
          {
            required: true,
            message: '请输入用户权限',
            trigger: 'blur',
          },
        ],
        fkCompanyId: [
          {
            required: true,
            message: '请选择用户单位',
            trigger: 'blur',
          },
        ],
        rule: [
          {
            required: true,
            message: '错误',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
        ],
        userName: [
          {
            required: true,
            message: '请输入登录名',
            trigger: 'blur',
          },
        ],
      },
      unitList: [],
      isShow: false,
    }
  },

  props: {
    id: {
      type: Number,
    },
  },
  methods: {
    async addUserFun(userInfo) {
      const { data: res } = await register(userInfo)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })

      this.form = null
      this.$parent.handleClose()
      // 因为element Ui 多层组件嵌套  所以$parent 是找到了 elementUi的组件
      this.$parent.$parent.findAllUserFun()
    },

    async findUserByIdFun() {
      const { data: res } = await findUserById(this.id)
      // this.form = res
      console.log('请求', res.data)
      const { fkJurisdictionId, fkCompanyId, name, userName, phone } = res.data
      console.log(fkJurisdictionId, fkCompanyId, name, userName, phone)
      this.form = { fkJurisdictionId, fkCompanyId, name, userName, phone, pkUserId: this.id }
    },
    async updateUserFun(userInfo) {
      const { data: res } = await updateUser(userInfo)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })

      this.form = null
      this.$parent.handleClose()
      // 因为element Ui 多层组件嵌套  所以$parent 是找到了 elementUi的组件
      this.$parent.$parent.findAllUserFun()
    },
    async findAllCompanyFun() {
      const { data: res } = await findAllCompany()
      console.log(res.data)
      this.unitList = res.data
      if (this.$store.state.power === 4) {
        this.isShow = true
        this.form.fkCompanyId = this.unitList[0].pkCompanyId
        console.log(this.fkCompanyId)
      }
    },
    // register
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.id) {
            this.form.password ? this.form.password  : null
            console.log('from', this.form)
            // 修改
            this.updateUserFun(this.form)
          } else {
            // 添加

            const userInfo = {
              fkJurisdictionId: this.form.fkJurisdictionId,
              fkCompanyId: this.form.fkCompanyId,
              userName: this.form.userName,
              name: this.form.name,
              password:this.form.password,
              phone: this.form.phone,
              rule: this.$store.state.power,
            }

            this.addUserFun(userInfo)
          }
        } else {
          this.$message({
            message: '请填写必填项',
            type: 'warning',
          })
          return false
        }
      })
    },

    onCancel() {
      this.$message('取消添加用户')
      this.$parent.handleClose()
    },
  },
  created() {
    this.findAllCompanyFun()
    console.log('id', this.id)
    this.id ? this.findUserByIdFun() : (this.form.fkCompanyId = this.$store.state.unitId - 0)
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.user ::v-deep .el-select,
.user ::v-deep .el-input {
  width: 400px;
}
</style>
